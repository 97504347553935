.login {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 20px;
}

.logo {
  margin: 90px 0;
  width: 146px;
}

.loginBtn {
  padding: 9px 10px !important;
  text-transform: capitalize !important;
  border-radius: 2px !important;
  margin-top: 35px !important;
  float: right;
}

.login form {
  width: 100%;
}

.recaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.error {
  text-align: center;
  font-size: 12px;
  color: #DA3F3F;
  margin-top: 10px;
  position: absolute;
  right: 20px;
}

.bottomBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 6px;
  background: #091F41;
}

.notice {
  position: fixed;
  bottom: 25px;
  left: 20px;
  width: 100%;
  height: 6px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  font-size: 12px;
  color: #161616;
}

:global .grecaptcha-badge {
  display: none;
}

:global .MuiFormControl-marginNormal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
