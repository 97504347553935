.landing{
  width: 100%;
  background-image: url('/Group.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100%;;
  padding-top: 20px;
  padding-bottom: 20px;
}
.landing img {
  max-height: 66px;
  max-width: 178px;
  width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 50px;
}
.landingDetails{
  padding: 0 25px;
  margin-bottom: 30px;
}
.landingDetails h3 {
  color: #54A8DE;
  font-family: Montserrat-Bold;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;

}
.landingDetails p {
  color: #FFFFFF;
  font-family: Montserrat-SemiBold;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 20px;
}

.landingFooter{
  width: 90%;
  display: flex;
  justify-content: flex-end;
}

.landingFooter button{
  max-height: 36px;
  max-width: 106px;
  border-radius: 2px;
  background-color: #00A19A;
  border: 0;
  outline: 0;
  color: white;
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  width: 100%;
}