.photoBlock, .videoStream, canvas {
  position: fixed;
  height: calc(100% - 108px);
  width: 100%;
  object-fit: cover;
  background: #000;
  color: #fff;
}

/* :global .photo {
  position: fixed;
  height: calc(100% - 200px);
  width: 100%;
  object-fit: cover;
} */

.flash {
  height: 25px;
  background: transparent;
  border: none;
  top: 25px;
  position: absolute;
  z-index: 1;
  left: 20px;
  padding: 0px;
}

.flash img {
  width: 100%;
  height: 100%;
}

.zoom {
  font-size: 40px;
    color: white;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
}

.zoom > span {
  font-size: 30px;
  line-height: 52px;
}

.capture {
  height: 37px;
  width: 37px;
  border: 1px solid white;
  object-fit: cover;
  top: calc(100% - 59px);
  position: absolute;
  left: 30px;
}

.toggle {
  height: 40px;
  width: 40px;
  border: none;
  background: transparent;
  top: calc(100% - 59px);
  position: absolute;
  right: 30px;
}

.toggle img {
  width: 40px;
  height: 40px;
}

.startPhoto {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  opacity: 0.65;
}

:global .MuiPaper-root {
  min-width: 95px !important;
  right: 20px;
  top: calc(100% - 91px) !important;
  left: initial !important;
  max-height: 90px !important;
}

:global .MuiInput-underline:before {
  border-color: white !important;
}

.selectCamera option {
  background: white;
}

.checkIcon {
  width: '40px';
  height: '40px';
  border-radius: '100%';
  color: green
}

.snap {
  position: absolute;
  top: calc(100% - 63px);
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: transparent;
  border: 1px solid white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.snap div{
  position: relative;
}

.snap span {
  width: 40px;
  height: 40px;
  background: white;
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.counter {
  position: absolute;
  top: calc(100% - 100px);
  width: 80px;
  height: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.messageError {
  padding: 10px;
  line-height: 23px;
}

:global .OTPublisherContainer {
  position: fixed;
  height: calc(100% - 108px);
  width: 100%;
  object-fit: cover;
}

:global .OT_publisher {
  display: none;
}