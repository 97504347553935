.galery {
  height: calc(100% - 143px);
  position: fixed;
  overflow: scroll;
  width: 100%;
}

.topGalery {
  width: 100%;
  height: 35px;
  background-color: #F2F2F2;
  position: fixed;
  top: 54px;
  z-index: 2;
}

.topGalery button {
  float: right;
  text-transform: capitalize;
  font-size: 12px;
}

.contentBlock {
  display: inline-block;
  height: 24.5vw;
  width: 24.5vw;
  margin: 0.25vw;
  object-fit: cover;
  background-color: white;
  position: relative;
}

.mediaContent {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.05);
  left: 0px;
  top: 0px;
}

.full {
  transition: opacity 400ms ease 0ms;
}

.thumb {
  filter: blur(12px);
  transform: scale(1);
  transition: visibility 0ms ease 200ms;
}

.contentBlock :global .MuiCheckbox-root {
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 0;
  color: white;
  pointer-events: none;
}

.videoIcon {
  position: absolute;
  z-index: 2;
  font-size: 12px;
  color: white;
  font-weight: 600;
  width: 17px;
  display: flex;
  align-items: center;
  pointer-events: none;
  left: 0; 
  right: 0; 
  top: 0;
  bottom: 0;
  margin: auto;
}

.addButton {
  position: fixed !important;
  bottom: 68px;
  right: 20px;
  background: blue;
  border-radius: 100% !important;
  width: 41px;
  height: 41px;
  min-width: 41px !important;
  z-index: 2; 
}

.sending {
  background: white;
  bottom: 56px;
  z-index: 3;
  width: 100%;
}

.fullScreenBlock {
  position: fixed;
  top: 88px;
  background: white;
  bottom: 56px;
  z-index: 3;
  width: 0;
}

.fullScreenBlock :global .swiper-container {
  width: 100%;
  height: calc(100% - 40px);
}

.fullScreenBlock :global .swiper-slide {
  width: 100% !important;
}

.fullScreenMedia {
  width: calc(100% - 5px);
  left: 0px;
  right: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0;
  height: 100%;
  object-fit: cover;
}

.fullScreenSlide {
  height: 100%;
  position: relative;
}

.fullScreenMenu {
  background-color: white;
  z-index: 3;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.fullScreenMenu_Button {
  height: 30px;
  border: none !important;
  text-transform: capitalize !important;
}

.fullScreenMenu_Button img {
  height: 16px;
}

.licensePlate, .email, .phoneNumber {
  margin-bottom: 20px;
  margin-top: 10px;
}

.date {
  color: #091F41;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
}

.formSender {
  padding: 20px;
  padding-top: 10px;
}

.formSender label {
  color: #8C8C8C !important;
  font-size: 12px;
}

.formSender textarea, .formSender input {
  color: black !important;
  font-size: 12px;
}

.formBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.formBtn .sendBtn {
  border-radius: 2px;
  text-transform: none;
}

.error {
  font-size: 12px;
  color: #DA3F3F;
  margin-top: 10px;
  position: relative;
  margin-left: 20px;
}