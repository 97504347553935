.notice {
  margin: 0 20px;
}

.logoContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.back {
  font-size: 10px;
  width: 96px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  font-family: initial;
  color: #091F41;
  top: 103px;
  left: 23px;
  position: absolute;
  cursor: pointer;
}
.back > img {
  width: 30px;
}

.logo {
  margin: 90px 0;
  width: 146px;
}

@media only screen and (max-width: 500px) {
  .back, .back > img {
    width: 25px;
  }

  .back {
    top: 110px;
  }
  
  .back > span {
    display: none;
  }
}

:global .otnotice-sections {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

:global .otnotice-content {
  padding: 0px !important;
}

:global .otnotice-section-content {
  margin-left: 0px !important;
  margin-right: 0px !important;
}