.checklistPage {
  padding: 40px;
  background: #F2F2F6;
  height: auto;
  display: inline-block;
  margin-bottom: 80px;
}

.checklistHeader {
  display: flex;
  align-items: center;
}

.checklistIcon {
  width: 60px;
}

.checklistHeader span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 76px;
  color: #1E3C81;
}

@media only screen and (max-width: 900px) {
  .checklistPage {
    padding: 20px;
  }
}
