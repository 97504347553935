.blockTypesSection {
  width: 45%;
  min-width: 400px;
}

.blockTypesSection:nth-child(1n) {
  float: left;
}

.blockTypesSection:nth-child(2n) {
  float: right;
}

.blockTypesContent {
  border-radius: 20px;
  background: #FFFFFF;
}

.blockTitleContent {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.blockTitle {
  display: flex;
  align-items: center;
}

.tbody span{
  display: inline-block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 27px;  
  color: #979797;
  transform: rotate(-38.34deg);
}

.blockTitle img{
  width: 30px;
  margin-right: 10px;
}


.blockTitle h1{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 51px;
  color: #1E3C81;
}

.ElementTitle {
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #161616;
  padding: 17px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
}

.ElementTitle::after {
  content: "";
  position: absolute;
  left: 30px;
  right: 0;
  bottom: 0;
  border: 1px solid #e0e0e060;
}

.blockTypesSection > .blockTypesContent > .ElementTitle:last-child::after {
  border: none;
}

.checkboxContent {
  display: flex;
}

.checkboxElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-left: 15px;
}

.checkboxElement span{
  font-size: 10px;
  text-align: center;
  color: #979797;
  margin-bottom: 3px;
}

input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #004089;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #004089;
  border-radius: 2px;
  transform: translateY(-0.075em);
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  font-size: 25px;
}

input[type="checkbox"]:checked::before {
  content: "✓";
}
.form {
  border-radius: 20px;
  background: #FFFFFF;
  padding: 17px 30px;
  margin-top: 40px;
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 16px;
}

.inputGroup {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  margin: 20px 0;
  color: #1E3C81;
  font-weight: bold;
  width: 100%;
  box-sizing:border-box;
}

.inputGroup .InputContent input {
  background: #FFFFFF;
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 10px;
  font-family: 'Montserrat';
  min-width: 200px;
  box-sizing:border-box;
}

.inputGroup .InputContent {
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
}


.inputGroup .InputContent span{
  margin-left: -1px;
  position: relative;
  right: 40px;
  width: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.inputGroup label {
  min-width: 200px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 840px) {
  .checklistContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .blockTypesSection:nth-child(n) {
    float: none;
  }

  .inputGroup .InputContent {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .blockTypesSection:nth-child(n) {
    min-width: 315px;
  }
}
