.todoSection {
  margin-bottom: 30px;
}

.todoSection h1{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 51px;
  color: #1E3C81;
}

.todoSection .blockTypesSection {
  padding: 15px
}

.todoSection .blockTypesSection:first-child {
  padding: 0 10px 10px 10px;
}

.todoSection .todoList {
  columns: 400px 2;
  grid-column-gap: 5rem;
  column-gap: 5rem;
  padding: 20px 0;
}

.todoSection .blockTypesContent {
  border-radius: 20px;
  background: #FFFFFF;
}

.todoSection .blockTitleContent {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.todoSection .blockTitle {
  display: flex;
  align-items: center;
}

.todoSection .tbody span{
  display: inline-block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 27px;  
  color: #979797;
  transform: rotate(-38.34deg);
}

.todoSection .blockTitle img{
  width: 20px;
  margin-right: 10px;
}

.todoSection .blockTitle h1{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 5px;
  color: #1E3C81;
}

.todoSection .ElementTitle {
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #161616;
  padding: 10px 30px 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
}

.todoSection .ElementTitle::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #e0e0e060;
}

.todoSection .blockTypesSection > .blockTypesContent > .ElementTitle:last-child::after {
  border: none;
}

.todoSection .checkboxContent {
  display: flex;
}

.todoSection .checkboxElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-left: 15px;
}

.todoSection .checkboxElement span{
  font-size: 10px;
  text-align: center;
  color: #979797;
  margin-bottom: 3px;
}

.todoSection input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #C4C4C4;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #C4C4C4;
  border-radius: 2px;
  transform: translateY(-0.075em);
}

.todoSection input[type="checkbox"]:disabled {
  border: 0.1em solid #C4C4C4;
  color: #C4C4C4;
  cursor: not-allowed;
}

.todoSection input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  font-size: 25px;
}

.todoSection input[type="checkbox"]:checked::before {
  content: "✓";
  color: #C4C4C4;
}

@media only screen and (max-width: 840px) {
  .todoSection .checklistContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .todoSection .blockTypesSection:nth-child(n) {
    float: none;
  }

  .todoSection .inputGroup input {
    width: 100%;
    height: 40px;
  }
  .todoSection .todoList {
    padding: 0;
  }
  .todoSection .blockTypesSection,
  .todoSection .blockTypesSection:first-child {
    padding: 0;
  }
}

@media only screen and (max-width: 500px) {
  .todoSection {
    margin-bottom: 0;
  }
  .todoSection .blockTypesSection:nth-child(n) {
    min-width: 315px;
  }
}
