.congrats{
  padding: 20px 25px;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('/stars.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-height: 230px;
  margin-bottom: 40px;
}
.header h2{
  color: #00A19A;
  font-family: Montserrat-Bold;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 28px;
}
.header span{
  color: #00A19A;
  font-family: Montserrat-Bold;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
}

.container{
  width: 100%;
  margin-bottom: 40px;
}
.container p {
  font-family: Montserrat-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  text-align: justify;
  position: relative;
  padding-left: 20px;
}
.container p::before{
  content: url('/Fill.svg');
  position: absolute;
  top: 0;
  left: 0;
}

.footer{
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer button {
  max-height: 36px;
  max-width: 106px;
  border-radius: 2px;
  background-color: #00A19A;
  border: 0;
  outline: 0;
  color: white;
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  width: 100%;
  cursor: pointer;
}