.media {
  width: 100%;
  position: relative;
  top: 53px;
  background: #F2F2F2;
}

.subMenuIcon {
  height: 22px;
  width: 22px;
  margin-bottom: 13px;
}

.mediaContainer :global .MuiButtonBase-root.Mui-disabled {
  color: #8C8C8C;
}

.deletePopup {
  position: absolute;
  width: 100%;
  background-color: rgba(140,140,140,0.7);
  bottom: 0px !important;
  top: 54px;
  left: 0px !important;
  right: 0px !important;
}

.deletePopup :global .MuiSnackbarContent-root {
  background: white;
  color: #2E2E2E;
  font-size: 14px;
  top: 100px !important;
  position: absolute;
  padding: 0;
  height: 142px;
  width: 318px;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.deletePopup :global .MuiPaper-root {
  max-height: initial !important;
}

.deletePopup :global .MuiSnackbarContent-action {
  width: 100%;
  margin-right: initial;
  padding-left: initial;
  justify-content: space-around;
  padding-left: 20px;
  padding-right: 20px;
}

.deletePopup :global .MuiSnackbarContent-message {
  width: 100%;
  text-align: center;
  padding: 0;
  padding-right: 33px;
  padding-left: 33px;
}

.confirmButton, .cancelButton {
  border-radius: 2px !important;
  Height: 36px;
  color: white !important;
  text-transform: none !important;
}

.confirmButton {
  background: #091F41  !important;
}

.cancelButton {
  background: #8C8C8C  !important;
}

.dot { 
  display: none;
  background: white !important;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  margin-bottom: 5px;
}

:global(.MuiButtonBase-root.Mui-selected) .dot {
  display: block;
}
