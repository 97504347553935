.header {
  background: #091F41;
  color: white;
  text-align: left;
  padding-left: 20px;
  height: 48px;
  line-height: 48px;
  font-size: 20px;
  position: fixed;
  width: 100%;
  margin-right: 20px;
  z-index:2;
}

.logo {
  height: 28px;
  margin-top: 10px;

}

.logout, .clear {
  background: transparent;
  border: none;
  cursor: pointer;
}

.logout {
  position: absolute;
  right: 20px;
  top: 10px;
  height: 26px;
}

.logout img {
  height: 26px;
}

.clear {
  width: 24px;
  height: 24px;
  padding: 0;
  position: relative;
  top: 5px;
  margin-right: 20px;
}

.clear svg {
  color: white;
}

.bar {
  position: fixed;
  top: 48px;
  width: 100%;
  height: 6px;
  background: #091F41;
  z-index: 2
}